import { ApolloClients } from '@vue/apollo-composable';
import { App, Component, createApp } from 'vue';

import { initHobbiiStarter } from '@/shared/instances/hobbii-starter';
import { getApolloClients } from '@/shared/utils/apollo-client';
import { contextualError } from '@/shared/utils/error';
import { setupI18n } from '@/shared/utils/i18n';

export const createCoreVueApp = async (
  component: Component,
  target: Element | string,
  props?: Record<string, unknown>,
  appDecorator?: ((app: App) => void | Promise<void>) | null,
  options?: {
    noClients: boolean;
  }
) => {
  const targetElement =
    typeof target === 'string' ? document.querySelector(target) : target;

  if (!targetElement) {
    throw contextualError('core-vue-.app', `Element "${target}" not found.`);
  }
  // we need to ensure Pinia is instantiated - there has to be a better way.
  await initHobbiiStarter();

  if (!window.hobbiiStore) {
    throw contextualError('core-vue-app', 'window.hobbiiStore not initialized');
  }

  const apolloClients = await getApolloClients();

  const i18n = await setupI18n(window.Shopify.locale);

  const app = createApp(component, props).use(window.hobbiiStore).use(i18n);

  if (!options?.noClients) {
    app.provide(ApolloClients, apolloClients);
  }

  await appDecorator?.(app);

  return app;
};
